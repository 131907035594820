import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row>
            <Col md={8} className="home-about-description">
              <h1 style={{ fontSize: "2.6em" }}>
                LET ME <span className="purple"> INTRODUCE </span> MYSELF
              </h1>
              <p className="home-about-body">
                Gaurav Avula, a passionate <span className="purple">Full Stack Developer</span> with experience in building scalable web applications using <span className="purple">React.js</span> and <span className="purple">Node.js</span>. Currently pursuing a <span className="purple">Master of Science in Computer Science</span> from the University of North Carolina Charlotte, I have a <span className="purple">Bachelor of Science in Computer Engineering</span> from the University at Buffalo, The State University of New York.
                <br />
                <br />My field of interest lies in <span className="purple">web technologies</span>, <span className="purple">product development</span>, and areas related to <span className="purple">Blockchain</span>. I am proficient in <span className="purple">Java</span>, <span className="purple">Python</span>, <span className="purple">JavaScript</span>, and <span className="purple">Android Application Development</span>, with hands-on experience in various tools and technologies such as <span className="purple">React</span>, <span className="purple">AngularJS</span>, <span className="purple">Docker</span>, and <span className="purple">AWS</span>.
                <br />
                <br />In my previous role as a <span className="purple">Full Stack Developer</span> at Credit Suisse Group, I designed and implemented scalable web applications, resulting in increased user engagement and reduced page load times. I also spearheaded the migration of Angular application components to React JS and ensured the security of applications by fixing vulnerabilities.
              </p>
            </Col>
            <Col md={4} className="myAvtar">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-social">
              <h1>FIND ME ON</h1>
              <p>
                Feel free to <span className="purple">connect </span>with me
              </p>
              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                      href="https://github.com/gauravavula"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <AiFillGithub />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                      href="https://twitter.com/gauravavula"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <AiOutlineTwitter />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                      href="https://www.linkedin.com/in/gauravavula/"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <FaLinkedinIn />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                      href="https://www.instagram.com/gauravavula"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons"
                  >
                    <AiFillInstagram />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
  );
}
export default Home2;
