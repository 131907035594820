import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
      <Card className="quote-card-view">
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p style={{ textAlign: "justify" }}>
              Hi Everyone, I am <span className="purple">Gaurav Avula</span>&nbsp;
              from <span className="purple"> Charlotte, North Carolina.</span>
              <br />
              I am currently employed as a <span className="purple">Full Stack Developer</span> at Credit Suisse Group.
              <br />
              I have completed a <span className="purple">Master of Science in Computer Science</span> from the University of North Carolina Charlotte.
              <br />
              <br />
              Apart from coding, some other activities that I love to do!
            </p>
            <ul>
              <li className="about-activity">
                <ImPointRight /> Playing Games
              </li>
              <li className="about-activity">
                <ImPointRight /> Writing Tech Blogs
              </li>
              <li className="about-activity">
                <ImPointRight /> Travelling
              </li>
            </ul>

            <p style={{ color: "rgb(155 126 172)" }}>
              "Success is not final, failure is not fatal: It is the courage to continue that counts." - Gaurav Avula{" "}
            </p>
          </blockquote>
        </Card.Body>
      </Card>
  );
}

export default AboutCard;
